/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */

/**
 * 自定义样式
 */
.main-content {
    min-height: calc(100vh - 40px);
}

.tea-menu {
    min-height: calc(100vh - 40px);
}

.flex {
    display: flex;
}

.block {
    display: block;
}

.flex-item1 {
    flex: 1;
}

.hide {
    opacity: 0;
}

.text-light-blue {
    color: #96C9FF !important;
}

.text-deep-blue {
    color: #0075FF !important;
}

.text-size-base {
    font-size: 12px;
}

.text-pre-line {
    white-space: pre-line
}

.fullwidth {
    width: 100%;
}

.selector {
    border: 1px solid #DDDDDD;
    font-size: 12px;
    line-height: 30px;
    width: 390px;
    margin-right: 20px;
}

.selector .title {
    align-items: center;
    font-weight: 900;
    height: 42px;
    color: #888888;
    border-bottom: 1px solid #DDDDDD;
    padding: 13px;
}

.selector .body {
    height: 299px;
    overflow-y: auto;
    overflow-x: hidden;
}

.selector .body > div, .selector .body > label {
    height: 40px;
    line-height: 40px;
    padding: 13px;
}

.selector .body > div:hover {
    background: #F2F2F2;
    cursor: pointer;;
}

.selector .body .tea-form-check {
    height: 40px;
    width: 100%;
    padding-left: 0;
}

.selector .body .tea-form-check .tea-checkbox {
    position: inherit;
}

.selector .body .tea-form-check .tea-checkbox span {
    margin-left: 5px;
}

.selector .body .selected {
    background: #F2F2F2;
}

.selector .item1 {
    width: 144px;
}

.selector .item2 {
    width: 245px;
}

.selector-clear-item {
    display: flex;
    align-items: center;
}

.selector-clear-item div {
    flex-grow: 2;
}

.selector-clear-item i {
    display: block;
    width: 20px;
}

.selector-body-left {
    border-right: 1px solid #DDDDDD;
}

.node-selector {
    position: relative;
}

.node-selector .tea-form__controls.tea-form__controls--text > .tea-icon-valid {
    margin-top: 0;
    position: absolute;
    left: 800px;
    top: 30px;
}

.node-selector .tea-form__help-text {
    margin-top: 0;
    position: absolute;
    left: 810px;
    top: 55px;
}

.auth-top-tip > div:first-child h3 {
    font-weight: 400;
}

.auth-top-tip > div:nth-child(2) {
    width: 320px;
}

.auth-percent-input {
    position: absolute;
    top: 6px;
    left: 185px;
}

.chain-upload .tea-form-upload-drag,
.chain-upload .tea-form-upload-drag .tea-form-upload__inner {
    width: 100%;
}

.chain-list-badge {
    position: relative;
    top: 5px;
}

.chain-agreement-body {
    white-space: pre-line;
    max-height: 60vh;
    overflow-y: auto;
}

/**
 overwrite some theme
 */
._cm-login-form__enter ._cm-login-vercode {
    width: 170px;
}

._cm-login-form__tit {
    text-align: center;
    letter-spacing: 1px;
}

._cm_login-vercode-input {
    width: 220px;
}

.chain-shell-input {
    width: 345px;
}

.content-mb-1n {
    margin-bottom: 5px;
}

.content-mb-2n {
    margin-bottom: 10px;
}

.content-mb-4n {
    margin-bottom: 20px;
}

.content-mt-1n {
    margin-top: 5px;
}

.content-mt-2n {
    margin-top: 10px;
}

.content-mt-3n {
    margin-top: 15px;
}

.content-mt-4n {
    margin-top: 20px;
}

.content-ml-1n {
    margin-left: 5px;
}

.content-mr-2n {
    margin-right: 10px;
}

.content-mr-4n {
    margin-right: 20px;
}

.content-pl-4n {
    padding-left: 20px;
}

.content-pl-8n {
    padding-left: 40px;
}

.tea-icon-m-size {
    width: 20px;
    height: 20px;
    background-size: contain;
}

.white {
    color: #fff;
}

.menu-tip {
    color: #c1c6c8;
    position: absolute;
    bottom: 100px;
    padding: 0 20px;
}

.tea-menu__list {
    height: 100%;
}

.tea-menu-is-collapsed .menu-tip {
    display: none;
}

.menu-tip .tea-icon-info {
    cursor: initial;
}

._cm-blockChain-item {
    position: relative;
}

.chain-item-delete {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.chain-code {
    margin-top: 4px;
    background-color: #e7eaef;
    border: 1px solid #cfd5de;
    width: 600px;
    padding: 10px;
}

.chain-deploy-node-name {
    width: 80px;
    word-break: break-all;
    white-space: normal;
}

.guide-title {
    text-align: center;
    font-size: 32px;
}

.guide-content .tea-step__description {
    color: #000;
}

.table-m {
    width: 800px;
}

.faq-content {
    justify-content: space-around;
    min-height: 50vh;
}

.faq-content > div {
    width: 250px;
    text-align: center;
}

.faq-content > div > img {
    width: 250px;
    margin-bottom: 10px;
}
